import { PriceModalityDTO } from '~/dto/PriceModalityDTO';
import { ModalityPaymentTypeDTO, ModalityPaymentTypeExtend } from '~/dto/ModalityPaymentTypeDTO';
import { KeyModality } from '~/class/KeyModality';

export type RestrictionType = 'unrestricted' | 'restricted-all-users' | 'restricted-selected-users' | '';

export class ModalityDTO {
  public id = 0;
  public orgaId = 0;
  public description = '';
  public occurence = 1;
  public type = 'fix';
  public interval = 1;
  public restriction?: RestrictionType = 'unrestricted';
  public feeType = 'amount';
  public feeAmount?: number;
  public amountMin?: number;
  public riskType = 'next-month';
  public riskDays = 0;
  public deleted = 0;
  public draft = false;
  public archiveId = 0;
  public date1Type = 'now';
  public startDate = ''
  public endDate = ''

  // DATE
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public dates:any = [];
  public days = 0;

  // ?
  public date1 = '';
  public date2 = '';
  public date3 = '';
  public date4 = '';
  public date5 = '';
  public date6 = '';
  public date7 = '';
  public date8 = '';
  public date9 = '';
  public date10 = '';
  public date11 = '';
  public date12 = '';

  // OPTIONS DE PERSONNALISATION
  public feeBool = false;
  public amountMinBool = false;

  // PRICE
  public pricesModality: PriceModalityDTO[] = [];
  public priceIdArray = [];

  // MODALITY PAYMENT TYPE
  public paymentsType: ModalityPaymentTypeExtend[] = [];
  public paymentTypeIdSelected: ModalityPaymentTypeDTO[] = this.paymentsType;

  // EXTEND
  public namePublic = '';

  constructor(init?: Partial<ModalityDTO>) {
    if (init === undefined) return
    Object.assign(this, init)
  }
}

export class ModalityExtend extends ModalityDTO {


  constructor(init?: Partial<ModalityExtend>) {
    super();
    if (init === undefined) return;
    Object.assign(this, init);
    this.setIntToDecimal();
  }

  setIntToDecimal() {
    // if (this.amountMin !== undefined && this.feeAmount !== undefined) {
    //   this.amountMin = this.amountMin > 0 ? this.amountMin / 100 : 0
    //   this.feeAmount = this.feeAmount > 0 ? this.feeAmount / 100 : 0
    // }

    this.amountMin = this.amountMin && this.amountMin > 0 ? this.amountMin / 100 : 0;
    this.feeAmount = this.feeAmount && this.feeAmount > 0 ? this.feeAmount / 100 : 0;
  }

  getType() {
    // = .name

    if (this.type === KeyModality.TYPE_FREE) {
      return 'Gratuit';
    } else if (this.type === KeyModality.TYPE_FIX) {
      return 'Date fixe';
    } else if (this.type === KeyModality.TYPE_FLEXIBLE) {
      return "A fixer entre la structure et l'élève";
    } else if (this.type === KeyModality.TYPE_SLIDE) {
      return 'Mois glissant';
    } else {
      return '';
    }
  }
}
